import { snakeCase } from "lodash";
import Router from "next/router";
import { useQuery } from "@tanstack/react-query";
import {
  gql,
  GraphQLClient,
  ClientError,
  type ResponseMiddleware,
} from "graphql-request";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const endpoint = "/api/graphql";
const responseMiddleware: ResponseMiddleware = (response) => {
  if (response instanceof ClientError && response.response.status === 401) {
    datadogRum.addError(response);
    datadogLogs.logger.warn(
      "User did not have a valid session, logging out",
      response,
    );
  }
};
export const client = new GraphQLClient(endpoint, {
  method: "POST",
  responseMiddleware,
});

/*******************************************************
 * SCORECARD QUERIES
 ******************************************************/

export const GRAPHQL_QUERY_BREAKOUT_METRICS_FOR_EMAIL = {
  key: ["query.breakoutMetricsForEmail"],
  query: gql`
    query breakoutMetricsForEmail($email: String!, $metricName: String!) {
      breakoutMetricsForEmail(email: $email, metricName: $metricName)
    }
  `,
};

export async function breakoutMetricsForEmail(email, metricName) {
  return client.request(GRAPHQL_QUERY_BREAKOUT_METRICS_FOR_EMAIL.query, {
    email,
    metricName,
  });
}

/**************************************************
 * GitHub queries
 **************************************************/

export const GRAPHQL_QUERY_IS_GITHUB_APP_INSTALLED = {
  key: ["query.isGithubAppInstalled"],
  query: gql`
    query {
      isGithubAppInstalled
    }
  `,
};

export async function isGitHubAppInstalled() {
  return client.request(GRAPHQL_QUERY_IS_GITHUB_APP_INSTALLED.query, {});
}

const GRAPHQL_MUTATE_CONNECT_GITHUB_INTEGRATION = {
  query: gql`
    mutation connectGithubIntegration($domainName: String!) {
      connectGithubIntegration(domainName: $domainName) {
        errors
      }
    }
  `,
};

export async function connectGitHubIntegration(domainName) {
  return client.request(GRAPHQL_MUTATE_CONNECT_GITHUB_INTEGRATION.query, {
    domainName,
  });
}

/**************************************************
 * Google queries
 **************************************************/

const GOOGLE_MARKETPLACE_APP_NOT_INSTALLED_ERROR = "App Not Installed";

function isGoogleMarketplaceAppInstalled(queryKey, queryFn) {
  return useQuery({
    queryKey,
    queryFn,
    retry: (failureCount, error) => {
      const maxPollCount = 60;

      if (error === GOOGLE_MARKETPLACE_APP_NOT_INSTALLED_ERROR && failureCount <= maxPollCount) {
        return true;
      }
      return false;
    },
    retryDelay: 2000,
    enabled: false, // We will call `refetch` manually from the installation modal
    useErrorBoundary: false,
  });
}


export const GRAPHQL_QUERY_IS_GOOGLE_MARKETPLACE_APP_INSTALLED = {
  key: ["query.isGoogleMarketplaceAppInstalled"],
  query: gql`
    query {
      isGoogleMarketplaceAppInstalled
    }
  `,
};

export function useIsGoogleMarketplaceWorkspaceAppInstalled() {
  return isGoogleMarketplaceAppInstalled(
    GRAPHQL_QUERY_IS_GOOGLE_MARKETPLACE_APP_INSTALLED.key,
    async () => {
      const res = await client.request<{ isGoogleMarketplaceAppInstalled: boolean }>(
        GRAPHQL_QUERY_IS_GOOGLE_MARKETPLACE_APP_INSTALLED.query,
        {}
      );

      if (res?.isGoogleMarketplaceAppInstalled === false) {
        throw GOOGLE_MARKETPLACE_APP_NOT_INSTALLED_ERROR;
      }

      return res;
    }
  );
}

export const GRAPHQL_QUERY_IS_GOOGLE_DRIVE_ACTIVITY_MARKETPLACE_APP_INSTALLED =
  {
    key: ["query.isGoogleDriveActivityMarketplaceAppInstalled"],
    query: gql`
      query {
        isGoogleDriveActivityMarketplaceAppInstalled
      }
    `,
  };

  export function useIsGoogleDriveActivityMarketplaceAppInstalled() {
    return isGoogleMarketplaceAppInstalled(
      GRAPHQL_QUERY_IS_GOOGLE_DRIVE_ACTIVITY_MARKETPLACE_APP_INSTALLED.key,
      async () => {
        const res = await client.request<{ isGoogleDriveActivityMarketplaceAppInstalled: boolean }>(
          GRAPHQL_QUERY_IS_GOOGLE_DRIVE_ACTIVITY_MARKETPLACE_APP_INSTALLED.query,
          {}
        );

        if (res?.isGoogleDriveActivityMarketplaceAppInstalled === false) {
          throw GOOGLE_MARKETPLACE_APP_NOT_INSTALLED_ERROR;
        }

        return res;
      }
    );
  }

/**********************
 * RingCentral queries
 ********************/

export async function fetchRingCentralCreateJWTLink() {
  return client.request(
    gql`
      query ringCentralCreateJwtLink {
        company {
          ringCentralCreateJwtLink
        }
      }
    `,
    {},
  );
}

const GRAPHQL_MUTATE_CONNECT_RING_CENTRAL_INTEGRATION = {
  query: gql`
    mutation connectRingCentralIntegration($token: String!) {
      connectRingCentralIntegration(token: $token) {
        errors
      }
    }
  `,
};

export async function connectRingCentralIntegration(token) {
  return client.request(GRAPHQL_MUTATE_CONNECT_RING_CENTRAL_INTEGRATION.query, {
    token,
  });
}

/**************************************************
 * User queries
 **************************************************/

export const GRAPHQL_QUERY_USERS = {
  key: ["query.users"],
  query: gql`
    query {
      users {
        email
        firstName
        lastName
        teamName
      }
    }
  `,
};

export async function getUsers() {
  return client.request(GRAPHQL_QUERY_USERS.query);
}

const GRAPHQL_MUTATE_CONNECT_GOOGLE_WORKSPACE_INTEGRATION = {
  query: gql`
    mutation connectGoogleWorkspaceIntegration($domainName: String!) {
      connectGoogleWorkspaceIntegration(domainName: $domainName) {
        errors
      }
    }
  `,
};

export async function connectGoogleWorkspaceIntegration(domainName) {
  return client.request(
    GRAPHQL_MUTATE_CONNECT_GOOGLE_WORKSPACE_INTEGRATION.query,
    {
      domainName,
    },
  );
}

const GRAPHQL_MUTATE_CONNECT_GOOGLE_DRIVE_ACTIVITY_INTEGRATION = {
  query: gql`
    mutation connectGoogleDriveActivityIntegration($domainName: String!) {
      connectGoogleDriveActivityIntegration(domainName: $domainName) {
        errors
      }
    }
  `,
};

export async function connectGoogleDriveActivityIntegration(domainName) {
  return client.request(
    GRAPHQL_MUTATE_CONNECT_GOOGLE_DRIVE_ACTIVITY_INTEGRATION.query,
    {
      domainName,
    },
  );
}

//////////////

const GRAPHQL_SUBMIT_DASHBOARD_REQUEST = {
  query: gql`
    mutation submitDashboardRequest($requestMessage: String!) {
      submitDashboardRequest(requestMessage: $requestMessage) {
        errors
      }
    }
  `,
};

export async function submitDashboardRequest(requestMessage) {
  return client.request(GRAPHQL_SUBMIT_DASHBOARD_REQUEST.query, {
    requestMessage,
  });
}

//////////////

const GRAPHQL_UPDATE_DASHBOARD_CONFIG = {
  query: gql`
    mutation updateDashboardConfig($config: JSON!) {
      updateDashboardConfig(config: $config) {
        reindexedConfig
        errors
      }
    }
  `,
};

export async function updateDashboardConfig(config) {
  return client.request(GRAPHQL_UPDATE_DASHBOARD_CONFIG.query, { config });
}

//////////////

const GRAPHQL_MUTATE_DISCONNECT_INTEGRATION = {
  query: gql`
    mutation disconnectIntegration($integrationId: ID!) {
      disconnectIntegration(integrationId: $integrationId) {
        errors
      }
    }
  `,
};

export async function disconnectIntegration(integrationId) {
  return client.request(GRAPHQL_MUTATE_DISCONNECT_INTEGRATION.query, {
    integrationId,
  });
}

//////////////

export const GRAPHQL_QUERY_USER_METADATA = {
  key: ["query.userMetadata"],
  query: gql`
    query {
      userMetadata {
        hasIntegrated
        hasIntegration
        hasCrmIntegrated
        canManageIntegrations
        modules
        dashboardConfig
        defaultDashboardConfig
      }
    }
  `,
};

export function useUserMetadata() {
  return useQuery(
    GRAPHQL_QUERY_USER_METADATA.key,
    async () => client.request(GRAPHQL_QUERY_USER_METADATA.query, {}),
    { staleTime: 60000 * 10 },
  );
}

//////////////

export async function searchTeamsAndEmployees(
  query,
  filterByRole,
) {
  return client.request(
    gql`
      query SearchTeamsAndEmployees(
        $query: String!
        $filterByRole: Boolean
      ) {
        company {
          searchTeamsAndEmployees(
            query: $query
            filterByRole: $filterByRole
          ) {
            teams {
              __typename
              id
              managerId
              fullName
            }
            employees {
              __typename
              id
              managerId
              fullName
            }
          }
        }
      }
    `,
    { query, filterByRole },
  );
}

//////////////

export async function loadAlertSubscriptions() {
  return client.request(
    gql`
      query AlertSubscriptions {
        user {
          alertSubscriptions {
            id
            condition {
              id
              module
              submodule
              description
            }
            enabled
          }
        }
      }
    `,
    {},
  );
}

export async function loadActiveAlerts() {
  return client.request(
    gql`
      query {
        user {
          activeAlert {
            createdAt
          }
          alertSubscriptions {
            condition {
              id
              module
              submodule
              description
              averagingWindow
              matches {
                fingerprint
                employee {
                  id
                  fullName
                  title
                }
                link
                data
              }
            }
          }
        }
      }
    `,
    {},
  );
}

export async function updateAlertSubscription(id, enabled) {
  return client.request(
    gql`
      mutation UpdateAlertSubscription($id: ID!, $enabled: Boolean!) {
        updateAlertSubscription(id: $id, enabled: $enabled) {
          alertSubscriptions {
            id
            condition {
              id
              module
              submodule
              description
              averagingWindow
            }
            enabled
          }
          errors
        }
      }
    `,
    { id: id, enabled: enabled },
  );
}

export async function submitAlertRequest(requestMessage) {
  return client.request(
    gql`
      mutation submitAlertRequest($requestMessage: String!) {
        submitAlertRequest(requestMessage: $requestMessage) {
          errors
        }
      }
    `,
    { requestMessage },
  );
}

export async function fetchMergeLinkToken() {
  return client.request(
    gql`
      query mergeLinkToken {
        company {
          mergeLinkToken
        }
      }
    `,
    {},
  );
}

export async function connectMergeHrisIntegration(publicToken) {
  return client.request(
    gql`
      mutation connectMergeHrisIntegration($publicToken: String!) {
        connectMergeHrisIntegration(publicToken: $publicToken) {
          integration {
            provider
          }
          errors
        }
      }
    `,
    { publicToken },
  );
}

export async function submitRequestForm(subject, message) {
  return client.request(
    gql`
      mutation submitRequestForm($subject: String!, $message: String!) {
        submitRequestForm(subject: $subject, message: $message) {
          errors
        }
      }
    `,
    { subject, message },
  );
}

export async function allTargetsForCompany(
  targetType,
  period,
  dateRange,
  metricField,
) {
  return client.request(
    gql`
      query AllTargetsForCompany(
        $targetType: TargetType!
        $dateRange: [ISO8601Date!]!
        $metricId: CommunicationAggregateMetricId!
      ) {
        company {
          communicationAggregatesAverage(
            dateRange: $dateRange
            targetType: $targetType
            metricId: $metricId
            sortTargetsBy: most_time
            allTargets: true
            limitPerTarget: 1
          ) {
            start
            targetType
            target
            targetDisplay
            avgCommunicationTime {
              value
              change
            }
            communicationTime {
              value
              change
            }
          }
        }
      }
    `,
    { targetType, period, dateRange, metricId: snakeCase(metricField) },
  );
}

export async function allTargetsForEmployee(
  targetType,
  period,
  dateRange,
  employeeId,
  depth,
  metricField,
) {
  return client.request(
    gql`
      query AllTargetsForEmployee(
        $targetType: TargetType!
        $metricId: CommunicationAggregateMetricId!
        $dateRange: [ISO8601Date!]!
        $employeeId: ID!
        $depth: AggregationDepth!
      ) {
        company {
          employee(id: $employeeId) {
            communicationAggregatesAverage(
              dateRange: $dateRange
              targetType: $targetType
              metricId: $metricId
              depth: $depth
              sortTargetsBy: most_time
              allTargets: true
              limitPerTarget: 1
            ) {
              start
              targetType
              target
              targetDisplay
              avgCommunicationTime {
                value
                change
              }
              communicationTime {
                value
                change
              }
            }
          }
        }
      }
    `,
    {
      targetType,
      period,
      dateRange,
      employeeId,
      depth,
      metricId: snakeCase(metricField),
    },
  );
}
