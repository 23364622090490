import { useState, useMemo, Fragment, ReactElement } from "react";
import { isEmpty } from "lodash";

import SearchInput from "@/molecules/SearchInput";
import { Menu, MenuItem } from "@/molecules/HoverMenu";

import s from "./style.module.css";

export type OptionEntry = {
  id: string;
  attributeName?: string;
  label: string | ReactElement;
  selectedLabel?: string | ReactElement;
  tooltip?: string | ReactElement;
  disabled?: boolean;
  children?: OptionEntry[];
};

interface TreeMenuProps {
  options: OptionEntry[];
  value: string;
  withSearch?: boolean;
  labelClass?: string;
  onChange: (entryId: string, fieldData: OptionEntry) => void;
}

function TreeMenu({
  options,
  labelClass,
  value,
  withSearch,
  onChange,
}: TreeMenuProps) {
  const [search, setSearch] = useState("");

  const selectableOptions = useMemo(
    () => getAllSelectableOptions(options),
    [options],
  );

  const [valueId, valueAttributeName] = value.split(";");
  const selected = selectableOptions.find(
    (o: OptionEntry) =>
      o.id === valueId &&
      (o.attributeName == null || o.attributeName == valueAttributeName),
  );

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const visibleGroups =
    withSearch && search !== ""
      ? options.filter((o) =>
          o.children.some((c) =>
            c.label.toString().toLowerCase().match(search.toLowerCase()),
          ),
        )
      : options;

  let label;

  if (selected?.selectedLabel) {
    label = <span className={labelClass}>{selected?.selectedLabel}</span>;
  } else if (selected?.label) {
    label = <span className={labelClass}>{selected?.label}</span>;
  } else {
    label = <></>;
  }

  return (
    <Menu label={label as any}>
      <div className={s.menu}>
        {withSearch && (
          <div className={s.withSearch}>
            <SearchInput
              value={search}
              onClear={handleClearSearch}
              onChange={handleChangeSearch}
            />
          </div>
        )}
        {visibleGroups.length === 0 && (
          <div className={s.empty}>
            <span className={s.clearSearch} onClick={handleClearSearch}>
              Clear Search
            </span>
          </div>
        )}
        {visibleGroups.map((group) => (
          <Fragment key={group.id}>
            <span className={s.group}>{group.label}</span>
            {group.children
              .filter((c) =>
                withSearch && search !== ""
                  ? c.label.toString().toLowerCase().match(search.toLowerCase())
                  : true,
              )
              .map((entry) =>
                entry.children?.length > 0 ? (
                  <Menu key={entry.id} label={entry.label as string}>
                    {entry.children.map((child) => (
                      <MenuItem
                        key={`${child.id}-${child.attributeName}`}
                        label={child.label}
                        disabled={entry.disabled}
                        tooltip={entry.tooltip}
                        onClick={() => {
                          onChange(child.id, child);
                          setSearch("");
                        }}
                      />
                    ))}
                  </Menu>
                ) : (
                  <MenuItem
                    key={`${entry.id}-${entry.attributeName}`}
                    label={entry.label}
                    disabled={entry.disabled}
                    tooltip={entry.tooltip}
                    onClick={() => {
                      onChange(entry.id, entry);
                      setSearch("");
                    }}
                  />
                ),
              )}
          </Fragment>
        ))}
      </div>
    </Menu>
  );
}

function getAllSelectableOptions(
  options: OptionEntry[],
  result: OptionEntry[] = [],
) {
  if (isEmpty(options)) {
    return result;
  }
  const selectable = options.filter((o) => isEmpty(o.children));
  const children = options
    .filter((o: OptionEntry) => !isEmpty(o.children))
    .flatMap((o: OptionEntry) => o.children);
  return getAllSelectableOptions(children, [...result, ...selectable]);
}

export default TreeMenu;
