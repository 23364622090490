import { useRef, type ChangeEventHandler, ReactNode } from "react";
import cn from "classnames";

import Check from "./check.svg";

import s from "./style.module.css";

interface Props {
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  halfChecked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  children?: ReactNode;
}

function Checkbox({
  id,
  onChange,
  disabled = false,
  checked = false,
  halfChecked = false,
  readOnly = false,
  children,
}: Props) {
  const ref = useRef(null);

  return (
    <>
      <input
        ref={ref}
        className={s.input}
        id={id}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
      />
      <span onClick={() => ref.current.click()} className={s.checkbox}>
        {checked && <Check className={s.check} />}
        {halfChecked && <span className={cn(s.check, s.halfChecked)}>-</span>}
      </span>
      <span onClick={() => ref.current.click()} className={s.wrapped}>
        {children}
      </span>
    </>
  );
}

export default Checkbox;
