import { useUser } from "@auth0/nextjs-auth0/client";

export function useL10n() {
  const { isLoading, user, error } = useUser();
  return {
    isLoading,
    error,
    // @ts-ignore
    l10n: user?.csMetadata?.l10n ?? {},
  };
}
