import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { sortBy, map } from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import { graphql } from "@/lib/gql";
import { SummaryHistoryQuery } from "@/lib/gql/graphql";
import { client } from "@/lib/graphql";
import Dialog from "@/atoms/Dialog";
import Trash from "@/atoms/icons/trash.svg";
import Button from "@/atoms/Button";
import { useSummaryState } from "../";

import s from "./style.module.css";

type SummaryList = SummaryHistoryQuery["actualUser"]["summaries"];

function useSummaryHistory(customerId?: string) {
  return useQuery({
    queryKey: ["summaryHistory", customerId],
    queryFn: async () => {
      const { actualUser } = await client.request(
        graphql(`
          query SummaryHistory($customerId: ID) {
            actualUser {
              summaries(customerId: $customerId) {
                id
                customerId
                title
                summary
                createdAt
              }
            }
          }
        `),
        { customerId },
      );
      return actualUser?.summaries;
    },
    useErrorBoundary: true,
  });
}

export function useDeleteSummary() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) =>
      client.request(
        graphql(`
          mutation DeleteSummary($id: ID!) {
            deleteSummary(id: $id) {
              summaries {
                id
                title
                summary
                createdAt
              }
              errors
            }
          }
        `),
        {
          id,
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["summaryHistory"],
      });
      queryClient.invalidateQueries({
        queryKey: ["summary"],
      });
    },
  });
}

function groupSummaries(summaries: SummaryList) {
  const today = [dayjs().startOf("day"), dayjs().endOf("day")];
  return [
    { name: "Today", range: today },
    {
      name: "Yesterday",
      range: [today[0].subtract(1, "day"), today[0]],
    },
    {
      name: "Previous 7 Days",
      range: [today[0].subtract(7, "day"), today[0].subtract(1, "day")],
    },
    {
      name: "Previous 30 Days",
      range: [today[0].subtract(30, "day"), today[0].subtract(7, "day")],
    },
    {
      name: "Older",
      range: [dayjs.unix(0), today[0].subtract(30, "day")],
    },
  ].map((g) => ({
    ...g,
    // Add summaries array to each group containing the summaries
    // falling in that date range. It's also important these are sorted
    // because it will determine the order in the UI.
    summaries: sortBy(
      summaries.filter((sm) =>
        dayjs(sm.createdAt).isBetween(g.range[0], g.range[1], "second", "[)"),
      ),
      "createdAt",
    ).reverse(),
  }));
}

function SummaryHistory() {
  const [summary, setSummary] = useSummaryState();
  const { data: summaryData, isLoading } = useSummaryHistory();
  const groups = groupSummaries(summaryData || []);

  const hasSummaries = groups.some((g) => g.summaries.length > 0);

  return hasSummaries ? (
    <ul className={s.history}>
      {map(
        groups,
        ({ name, summaries }) =>
          summaries.length > 0 && (
            <HistoryGroup
              key={name}
              name={name}
              summaries={summaries}
              activeSummaryId={summary?.id}
              onClickEntry={setSummary}
            />
          ),
      )}
    </ul>
  ) : isLoading ? (
    <span className={s.loading} />
  ) : (
    <span className={s.empty}>
      <span>
        No summaries have been generated yet. Your summary history will appear
        here.
      </span>
    </span>
  );
}

interface HistoryGroupProps {
  summaries: SummaryList;
  activeSummaryId?: string;
  name: string;
  onClickEntry: (summary: { id: string; customerId: string }) => void;
}

function HistoryGroup({
  summaries,
  activeSummaryId = "7",
  name,
  onClickEntry,
}: HistoryGroupProps) {
  const [confirmDelete, setConfirmDelete] = useState(null);

  const deleteSummary = useDeleteSummary();

  const handleDelete = () => {
    deleteSummary.mutate(confirmDelete.id, {
      onSuccess: () => {
        setConfirmDelete(null);
      },
    });
  };

  return (
    <li className={s.historyGroup}>
      {confirmDelete && (
        <ConfirmDelete
          summary={confirmDelete}
          onDelete={handleDelete}
          onCancel={() => setConfirmDelete(null)}
        />
      )}
      <span className={s.groupTitle}>{name}</span>
      <ul className={s.summaryList}>
        {summaries?.map((summary) => (
          <li
            key={summary.id}
            className={summary.id === activeSummaryId ? s.currentSummary : null}
            onClick={() => onClickEntry({ id: summary.id, customerId: summary?.customerId })}
          >
            {summary.title}
            <Button
              size="small"
              style="text"
              onClick={() => setConfirmDelete(summary)}
            >
              <Trash className={s.trash} width={16} height={16} />
            </Button>
          </li>
        ))}
      </ul>
    </li>
  );
}

interface ConfirmDeleteProps {
  summary: SummaryList[0];
  onDelete: () => void;
  onCancel: () => void;
}

function ConfirmDelete({ onDelete, onCancel, summary }: ConfirmDeleteProps) {
  return (
    <Dialog
      open
      actions={() => (
        <div className={s.confirmationActions}>
          <Button onClick={onCancel} style="secondary" size="large">
            Cancel
          </Button>
          <Button size="large" onClick={onDelete}>
            Delete
          </Button>
        </div>
      )}
    >
      <span className={s.confirmationTitle}>Delete summary?</span>
      <span className={s.confirmationSubtitle}>
        <p>
          The following summary will be permanently deleted from your history:
        </p>
        <p className={s.confirmSummaryTitle}>{summary.title}</p>
        <p>Are you sure you want to proceed?</p>
      </span>
    </Dialog>
  );
}

export default SummaryHistory;
