import { type ReactNode } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import Popup from "@/atoms/Popup";

import styles from "./style.module.css";

export const widths = ["wide", "narrow"] as const;

interface Props {
  open: boolean;
  onClose?: () => void;
  narrow?: boolean;
  width?: (typeof widths)[number];
  children: ReactNode;
  header?: () => ReactNode;
  actions?: () => ReactNode;
  className?: string;
  headerClassName?: string;
  closeOnOutsideClick?: boolean;
}

function Dialog({
  open,
  onClose,
  width,
  children,
  header,
  actions,
  className,
  headerClassName,
  closeOnOutsideClick = true,
}: Props) {
  function handleKeyDown(e) {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        e.target.blur();
        onClose();
        break;
    }
  }

  return open
    ? createPortal(
        <div className={styles.dialog} onKeyDown={handleKeyDown}>
          <Popup
            closeOnOutsideClick={closeOnOutsideClick}
            open={open}
            onClose={onClose}
            className={cn(styles.pane, { [styles.narrow]: width === "narrow", [styles.wide]: width === "wide" })}
          >
            {header && (
              <div className={cn(styles.header, headerClassName)}>
                {header()}
              </div>
            )}
            <div className={cn(styles.body, className)}>{children}</div>
            {actions && <div className={styles.actions}>{actions()}</div>}
          </Popup>
        </div>,
        document.body,
      )
    : null;
}

export default Dialog;
