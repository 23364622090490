import { forwardRef, ReactNode, MouseEventHandler, Ref } from "react";
import Loader from "@/atoms/Loader";
import styles from "./style.module.css";
import classnames from "classnames";

export const variants = [
  "primary",
  "secondary",
  "tertiary",
  "text",
  "selected",
  "deselected",
] as const;

export const sizes = ["large", "medium", "small", "smallest"] as const;

export interface Props {
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  style?: (typeof variants)[number];
  size?: (typeof sizes)[number];
  className?: string;
  children?: ReactNode;
  type?: "button" | "submit";
  loading?: boolean;
  title?: string;
}

function Button(
  {
    onClick,
    disabled,
    style = "primary",
    size = "medium",
    className,
    children,
    type = "button",
    loading = false,
    ...rest
  }: Props,
  ref: Ref<HTMLInputElement>,
) {
  const classes = classnames(styles.button, {
    // Size
    [styles.large]: size === "large",
    [styles.medium]: size === "medium",
    [styles.small]: size === "small",
    [styles.smallest]: size === "smallest",
    // Style
    [styles.primary]: style === "primary",
    [styles.secondary]: style === "secondary",
    [styles.tertiary]: style === "tertiary",
    [styles.text]: style === "text",
    [styles.selected]: style === "selected",
    [styles.deselected]: style === "deselected",
    [styles.loading]: loading,
  });

  if (type === "submit" && typeof children === "string") {
    return (
      <input
        type="submit"
        className={classnames(classes, className)}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        value={children}
        {...rest}
      />
    );
  } else {
    return (
      <button
        className={classnames(classes, className)}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {loading && <Loader className={styles.loader} />}
        <span className={styles.childrenWrapper}>{children}</span>
      </button>
    );
  }
}

export default forwardRef(Button);
