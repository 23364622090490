import { useState, useCallback, useMemo, ReactNode } from "react";
import cn from "classnames";
import { isNil, isArray } from "lodash";

import DropdownInput, {
  DropdownIcon,
  DropdownStyle,
} from "@/molecules/DropdownInput";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/molecules/Tooltip";
import InfoIcon from "@/atoms/InfoIcon";
import Checkbox from "@/atoms/Checkbox";

import styles from "./style.module.css";

export { DropdownStyle };

export enum SelectStyle {
  Button = "button",
  Pill = "pill",
}

interface Props {
  options: OptionEntry[];
  value: string | string[];
  onChange: (value: string | string[]) => void;
  closeOnSelect?: boolean;
  multi?: boolean;
  className?: string;
  style?: DropdownStyle;
  icon?: DropdownIcon;
  disabled?: boolean;
}

type OptionEntry = {
  id: string;
  label: ReactNode;
  tooltip?: string;
};

function Select({
  options,
  value,
  onChange,
  className,
  disabled = false,
  closeOnSelect = true,
  style = DropdownStyle.Muted,
  icon = DropdownIcon.DownArrow,
}: Props) {
  const [open, setOpen] = useState(false);

  const multi = isArray(value);
  const values = useMemo(
    () => (multi ? value : isNil(value) ? [] : [value]),
    [multi, value],
  );
  const selectedOptions = options.filter((o) => values.includes(o.id));

  const buttonLabel = (
    <ul className={cn(styles.buttonLabel, { [styles.multi]: multi })}>
      {selectedOptions.map((o) => (
        <li key={o.id}>{o.label}</li>
      ))}
    </ul>
  );

  const handleOnChange = useCallback(
    (id: string) => {
      if (multi) {
        const removed = values.find((v) => v === id);
        if (removed) {
          onChange(values.filter((v) => v !== id));
        } else {
          onChange([...values, id]);
        }
      } else {
        onChange(id);
      }
      closeOnSelect && setOpen(false);
    },
    [onChange, closeOnSelect, values, multi],
  );

  return (
    <DropdownInput
      open={open}
      onOpenChange={setOpen}
      className={cn(styles.root, className)}
      style={style}
      icon={icon}
      label={buttonLabel}
    >
      <ul className={styles.optionGroup}>
        {options.map((entry) => (
          <OptionsEntry
            multi={multi}
            key={entry.id}
            onClick={handleOnChange}
            entry={entry}
            isSelected={values.includes(entry.id)}
            disabled={disabled}
          />
        ))}
      </ul>
    </DropdownInput>
  );
}

interface OptionEntryProps {
  onClick: (id: string) => void;
  entry: OptionEntry;
  disabled?: boolean;
  isSelected: boolean;
  multi?: boolean;
}

function OptionsEntry({
  onClick,
  entry,
  isSelected,
  disabled = false,
  multi = false,
}: OptionEntryProps) {
  const { id, label, tooltip } = entry;
  const classes = cn({
    [styles.selected]: isSelected,
    [styles.disabled]: disabled,
  });
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClick(id);
    },
    [onClick, id],
  );
  return (
    <li className={classes} onClick={disabled ? null : handleClick}>
      <label htmlFor={id}>
        {multi && <Checkbox checked={isSelected} readOnly />}
        <span>{label}</span>
        {tooltip ? (
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon />
            </TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        ) : null}
      </label>
    </li>
  );
}

export default Select;
