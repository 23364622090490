import { useState } from "react";

import Drawer from "@/molecules/Drawer";
import Toggle from "@/atoms/Toggle";
import Button from "@/atoms/Button";
import { EntitySelector } from "@/lib/gql/graphql";
import { useTeamFilter } from "../";
import EntitySelect from "@/organisms/EntitySelect";
import SquigglyArrow from "./squiggly-arrow.svg";

import styles from "./style.module.css";

interface Props {
  headerText?: string;
  ctaText?: string;
  promptText?: string;
  onClose: () => void;
  open: boolean;
  filterByRole?: boolean;
  excludeEntireTeamOption?: boolean;
  excludeTeamsFromSearch?: boolean;
  updateQueryState?: boolean;
  focusOnSearchRef?: boolean;
  savePendingSelectors?: (selectors: EntitySelector[]) => void;
  formContent?: any;
  preSelectedSelectors?: EntitySelector[];
}

function TeamFilterSidebar({
  headerText = "Filter Team",
  ctaText = "Apply",
  promptText = "Start searching for teams or employees to explore their activity.",
  onClose,
  open,
  filterByRole = true,
  excludeEntireTeamOption = false,
  excludeTeamsFromSearch = false,
  updateQueryState = true,
  focusOnSearchRef = true,
  savePendingSelectors,
  formContent,
  preSelectedSelectors,
}: Props) {
  const [selectors, setSelectors] = useTeamFilter();
  const [pendingSelectors, setPendingSelectors] = useState(
    preSelectedSelectors || selectors,
  );
  const entireTeamSelected = (pendingSelectors || []).length === 0;

  const handleApply = async () => {
    if (updateQueryState) {
      await setSelectors(pendingSelectors);
    } else if (savePendingSelectors) {
      savePendingSelectors(pendingSelectors);
    }

    onClose();
  };

  return (
    <Drawer onClose={onClose} open={open}>
      <div className={styles.body}>
        <div className={styles.header}>
          <div className={styles.title}>{headerText}</div>
          {!excludeEntireTeamOption && (
            <div className={styles.toggleContainer}>
              <Toggle
                on={entireTeamSelected}
                className={styles.toggle}
                onChange={() => setPendingSelectors([])}
              />
              Entire Team
            </div>
          )}
        </div>

        {formContent}

        <EntitySelect
          value={pendingSelectors}
          onChange={setPendingSelectors}
          filterByRole={filterByRole}
          excludeTeamsFromSearch={excludeTeamsFromSearch}
          focusOnMount={focusOnSearchRef}
          prompt={
            <div className={styles.emptyTable}>
              <SquigglyArrow />
              <div>{promptText}</div>
            </div>
          }
        />
      </div>
      <div className={styles.footer}>
        <Button style="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          style="primary"
          onClick={handleApply}
          className={styles.primaryButton}
        >
          {ctaText}
        </Button>
      </div>
    </Drawer>
  );
}

export default TeamFilterSidebar;
