import React, { useState, type ReactNode } from "react";
import { createPortal } from "react-dom";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
} from "@floating-ui/react";

import s from "./style.module.css";

interface SimpleTooltipProps {
  children?: ReactNode;
  content?: ReactNode;
}

function SimpleTooltip({ children, content }: SimpleTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles } = useFloating({
    placement: "bottom",
    middleware: [offset(16), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  return (
    <div
      ref={refs.setReference}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {children}
      {isOpen &&
        createPortal(
          <div ref={refs.setFloating} className={s.root} style={floatingStyles}>
            <div className={s.tooltipContent}>{content}</div>
          </div>,
          document.body,
        )}
    </div>
  );
}

export default SimpleTooltip;
