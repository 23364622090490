import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import cn from "classnames";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryErrorResetBoundary,
} from "@tanstack/react-query";
import { capitalize, isNil } from "lodash";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Markdown from "react-markdown";
import { useQueryState, queryTypes, HistoryOptions } from "next-usequerystate";
import { useUser } from "@auth0/nextjs-auth0/client";
import dayjs, { type Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

import { graphql } from "@/lib/gql";
import {
  EntitySelector,
  Tool,
  Sentiment,
  FeedbackType,
} from "@/lib/gql/graphql";
import { client } from "@/lib/graphql";
import { DateRangePill, useDatePicker } from "@/molecules/DatePicker";
import { TeamFilterPill, useTeamFilter } from "@/organisms/TeamFilter";
import SimpleTooltip from "@/molecules/SimpleTooltip";
import DialogDrawer from "@/templates/DialogDrawer";
import Loader from "@/atoms/Loader";
import Pill from "@/atoms/Pill";
import Magic from "@/atoms/icons/magic.svg";
import Button, { type Props as ButtonProps } from "@/atoms/Button";
import SummaryHistory from "./SummaryHistory";
import SummaryFeedback from "./SummaryFeedback";

import s from "./style.module.css";
import CrmThemeMenu from "../CrmThemeMenu";
import { UserCsMetadata } from "@/components/AuthedLayout";

function useShowSummary() {
  return useQueryState("showSummary", {
    ...queryTypes.boolean.withDefault(false),
    history: "push",
  });
}

export function useSummaryState(history: HistoryOptions = "push") {
  return useQueryState("summary", {
    ...queryTypes.json<{ id: string; customerId: string }>().withDefault(null),
    history,
  });
}

function useCreateSummary() {
  return useMutation({
    mutationFn: async ({
      /* @ts-ignore */
      customerId,
      /* @ts-ignore */
      from,
      /* @ts-ignore */
      to,
      /* @ts-ignore */
      teamFilters,
      /* @ts-ignore */
      tools,
      /* @ts-ignore */
      ticketLabels,
      /* @ts-ignore */
      ticketSentiment,
    }) => {
      const { createSummary: res } = await client.request(
        graphql(`
          mutation CreateSummary(
            $customerId: ID
            $from: ISO8601Date!
            $to: ISO8601Date!
            $teamFilters: [TeamFilter!]
            $tools: [Tool!]
            $ticketLabels: [String!]
            $ticketSentiment: Sentiment
          ) {
            createSummary(
              customerId: $customerId
              from: $from
              to: $to
              teamFilters: $teamFilters
              tools: $tools
              ticketLabels: $ticketLabels
              ticketSentiment: $ticketSentiment
            ) {
              status
              summary {
                id
                status
                createdAt
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                tools
                ticketSentiment
                from
                to
                feedbackType
              }
              errors
            }
          }
        `),
        {
          customerId,
          from,
          to,
          teamFilters,
          tools,
          ticketLabels,
          ticketSentiment,
        },
      );
      if (res.errors?.length > 0) {
        throw new Error(res.errors.join(", "));
      }
      return res?.summary;
    },
    useErrorBoundary: true,
  });
}

function useSummary(id: string) {
  return useQuery({
    queryKey: ["summary", id],
    queryFn: async () => {
      if (isNil(id)) {
        return null;
      }
      const { actualUser } = await client.request(
        graphql(`
          query Summary($id: ID!) {
            actualUser {
              summary(id: $id) {
                id
                themedSummaryId
                status
                createdAt
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                tools
                ticketSentiment
                from
                to
                feedbackType
              }
            }
          }
        `),
        { id },
      );

      const summary = actualUser?.summary;
      if (!isNil(summary) && summary.status !== "done") {
        throw "Not done";
      }
      return summary;
    },
    retry: (failureCount, error) => {
      const maxPollCount = 15;
      if (error === "Not done" && failureCount <= maxPollCount) {
        return true;
      }
      return false;
    },
    retryDelay: 2000,
    useErrorBoundary: true,
  });
}

function useSummaryByTheme(id: string, crmTheme: { attribute: string; segment: string; label?: string } | null) {
  return useQuery({
    queryKey: ["summaryByTheme", id, crmTheme],
    queryFn: async () => {
      if (isNil(id)) {
        return null;
      }
      const { actualUser } = await client.request(
        graphql(`
          query SummaryByTheme($id: ID!, $crmTheme: CrmThemeInput) {
            actualUser {
              summaryByTheme(id: $id, crmTheme: $crmTheme) {
                id
                themedSummaryId
                status
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                tools
                ticketSentiment
                from
                to
                feedbackType
              }
            }
          }
        `),
        { id, crmTheme },
      );

      const summary = actualUser?.summaryByTheme;
      if (!isNil(summary) && summary.status !== "done") {
        throw "Not done";
      }
      return summary;
    },
    retry: (failureCount, error) => {
      const maxPollCount = 15;
      if (error === "Not done" && failureCount <= maxPollCount) {
        return true;
      }
      return false;
    },
    retryDelay: 2000,
    useErrorBoundary: true,
  });
}

function Summaries() {
  const { user } = useUser();
  const [displaySummary, setShowSummary] = useShowSummary();
  const [summary, setSummary] = useSummaryState();

  const aiEnabled = user?.company_ai_enabled;
  const userMetadata = user?.csMetadata as UserCsMetadata;
  const aiSummaryByThemeEnabled = userMetadata?.ai_summary_config?.crm_themed_summary.users === "*";

  const handleClose = () => {
    setShowSummary(null);
    setSummary(null);
  };

  return displaySummary ? (
    <DialogDrawer
      title={<span>Summaries</span>}
      width={865}
      onClose={handleClose}
    >
      {!aiEnabled ? (
        <div className={s.optedOut}>
          You have opted out of AI features. To opt back in, please reach out to{" "}
          <a href="mailto:support@lumopath.ai">support@lumopath.ai</a>
        </div>
      ) : (
        <div className={s.layout}>
          <SummaryHistory />
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary onReset={reset} FallbackComponent={SummaryError}>
                <Summary id={summary?.id} customerId={summary?.customerId} aiSummaryByThemeEnabled={aiSummaryByThemeEnabled}/>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </div>
      )}
    </DialogDrawer>
  ) : null;
}

interface SummaryProps {
  id: string;
  customerId: string | null;
  aiSummaryByThemeEnabled: boolean;
}

function Summary({ id, customerId, aiSummaryByThemeEnabled }: SummaryProps) {
  const showSummaryByTheme = aiSummaryByThemeEnabled && !customerId;
  const [segment, setSegment] = useQueryState(
    "segment",
    queryTypes.json<{ attribute: string; segment: string; label?: string }>().withDefault({ attribute: "general", segment: "overview" }),
  );
  const { data: summary, isLoading: loading, refetch } = showSummaryByTheme ? useSummaryByTheme(id, segment) : useSummary(id);
  const {
    from,
    to,
    teamFilters,
    customerName,
    title,
    summary: summaryBody,
    feedbackType,
    tools,
    ticketSentiment,
  } = summary || {};

  const handleSegmentChange = useCallback((newSegment) => {
    setSegment(newSegment);
    refetch();
  }, [setSegment]);

  return (
    <div className={s.summary}>
      <div className={s.filters}>
        <TeamFilterPill entities={teamFilters} />
        <DateRangePill dateRange={[from, to]} />
        {!loading && <Pill>{customerName ?? "All activity"}</Pill>}
        {tools && tools.length > 0 && (
          <Pill>{tools.map(capitalize).join(", ")}</Pill>
        )}
        {ticketSentiment && <Pill>{capitalize(ticketSentiment)} CSAT</Pill>}
      </div>

      {showSummaryByTheme && (
        <CrmThemeMenu
          includeGeneralSegment
          includeProactiveReactiveSegment
          includeInternalSegment
          includeAccountValueSegment
          includeToolSegment
          customerAttributesAllowList={["account_segment", "lifecycle_stage"]}
          className={s.crmThemeMenu}
          selectedSegment={segment}
          onChange={handleSegmentChange}
        />
      )}

      {!loading && <div className={s.title}>{title}</div>}
      <div className={s.body}>
        {loading || isNil(id) ? (
          <Loading />
        ) : isNil(summary) ? (
          <SummaryNotFound />
        ) : (
          <SummaryBody
            summaryId={id}
            themedSummaryId={summary.themedSummaryId}
            body={summaryBody}
            feedbackType={feedbackType}
          />
        )}
      </div>
    </div>
  );
}

interface SummaryBodyProps {
  body: string;
  summaryId: string;
  themedSummaryId: string;
  feedbackType?: FeedbackType;
}

function SummaryBody({ summaryId, themedSummaryId, body, feedbackType }: SummaryBodyProps) {
  return (
    <div className={s.summaryBody}>
      <Markdown>{body}</Markdown>
      <SummaryFeedback
        summaryId={summaryId}
        themedSummaryId={themedSummaryId}
        summaryBody={body}
        feedbackType={feedbackType}
      />
    </div>
  );
}

const LONG_LOAD_THRESHOLD_MS = 15000;

function Loading() {
  const [longLoad, setLongLoad] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (!timeoutId && !longLoad) {
      setTimeoutId(setTimeout(() => setLongLoad(true), LONG_LOAD_THRESHOLD_MS));
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [timeoutId, longLoad]);

  return (
    <div className={s.loading}>
      <div className={s.summaryLoader}>
        <Loader width={24} height={24} />
        {!longLoad ? (
          <span>Generating Summary…</span>
        ) : (
          <span>Just a little bit longer…</span>
        )}
      </div>
    </div>
  );
}

function SummaryNotFound() {
  return (
    <div className={s.timeoutError}>
      <div className={s.message}>
        <div className={s.header}>No Summary Selected</div>
        <p className={s.caption}>
          Please select a summary from the menu on the left or navigate back to
          the main page generate a new one.
        </p>
      </div>
    </div>
  );
}

function SummaryError({ resetErrorBoundary }: FallbackProps) {
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      resetErrorBoundary();
    });
    return () => {
      router.events.off("routeChangeComplete", () => null);
    };
  });
  return (
    <div className={s.timeoutError}>
      <div className={s.message}>
        <div className={s.header}>Summary Processing in The Background</div>
        <p className={s.caption}>
          Due to a higher than normal volume, your summary is taking a little
          longer than usual. It’s still being processed in the background, so
          please check back soon. Thanks for your patience.
        </p>
        <Button onClick={resetErrorBoundary}>Retry</Button>
      </div>
    </div>
  );
}

type AiSummaryButtonProps = ButtonProps & {
  customerId?: string;
  teamFilters?: EntitySelector[];
  dateRange?: Dayjs[];
  tools?: Tool[];
  ticketLabels?: string[];
  ticketSentiment?: Sentiment;
};

export function AiSummaryButton({
  customerId = null,
  teamFilters,
  dateRange,
  tools = [],
  ticketLabels = [],
  ticketSentiment = null,
  style = "text",
  size = "smallest",
  disabled = false,
  className = null,
  type = "button",
  children = null,
  loading = false,
}: AiSummaryButtonProps) {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [urlDateRange] = useDatePicker();
  const [urlTeamFilters] = useTeamFilter();
  const [_, setShowSummary] = useShowSummary();
  const [__, setSummary] = useSummaryState("replace");
  const createSummary = useCreateSummary();

  /* NOTE: Temporarily enable employee summaries only for some users */
  /* --------------------------------------------------------------- */
  const ENABLED_COMPANIES = [
    -78, // Great Company 78
    1, // Lumopath
    9, // Shipwell
    32, // Engagedly
    35, // Whatnot
    69, // ACST
    74, // Petdesk
    94, // Aurora Solar
    105, // Peek
    114, // Foursquare
    123, // Kenect
    125, // InMarket
    130, // morningconsult.com
    131, // Partnerize
    132, // siena.cx
    134, // SmarterX
  ];
  const ENABLED_USERS = ["roderick@lovevery.com"];
  const isEmployeeSummary = teamFilters?.length === 1 && isNil(customerId);
  let isEnabledForUser = true;
  if (isEmployeeSummary) {
    isEnabledForUser =
      ENABLED_COMPANIES.includes(user?.company_id as number) ||
      ENABLED_USERS.includes(user?.email);
  }
  /* --------------------------------------------------------------- */

  return (
    isEnabledForUser && (
      <SimpleTooltip content="See summary of activities">
        <Button
          data-feature={
            isEmployeeSummary
              ? "ai-employee-summary-button"
              : "ai-summary-button"
          }
          className={cn(s.aiButton, className)}
          type={type}
          size={size}
          style={style}
          disabled={disabled}
          loading={loading}
          onClick={(e) => {
            const [from, to] = dateRange || urlDateRange;
            e.stopPropagation();
            setSummary(null);
            setShowSummary(true);
            createSummary.mutate(
              /* @ts-ignore: Can't figure out this type error for the life of me */
              {
                customerId,
                from,
                to,
                teamFilters: teamFilters || urlTeamFilters,
                tools,
                ticketLabels,
                ticketSentiment,
              },
              {
                onSuccess: (summary) => {
                  queryClient.invalidateQueries({
                    queryKey: ["summaryHistory"],
                  });
                  setSummary({ id: summary?.id, customerId: summary?.customerId });
                },
              },
            );
          }}
        >
          <Magic width={12} height={12} />
          {children}
        </Button>
      </SimpleTooltip>
    )
  );
}

export default Summaries;
