import { useState, ReactNode } from "react";
import { isNil } from "lodash";
import { flushSync } from "react-dom";
import cn from "classnames";
import {
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useRole,
  autoUpdate,
  offset,
  shift,
  flip,
  size,
  FloatingFocusManager,
} from "@floating-ui/react";

import s from "./style.module.css";

export enum DropdownIcon {
  DownArrow = "down_arrow",
  RightArrow = "right_arrow",
  Calendar = "calendar",
  Plus = "plus",
  Check = "check",
}

export enum DropdownIconPosition {
  Right = "right",
  Left = "left",
}

export enum DropdownStyle {
  Muted = "muted",
  Primary = "primary",
  Secondary = "secondary",
  Text = "text",
  Pill = "pill",
  Deselected = "deselected",
  Selected = "selected",
}

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  icon?: DropdownIcon;
  iconPosition?: DropdownIconPosition;
  label: ReactNode;
  style?: DropdownStyle;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

function DropdownInput({
  open,
  onOpenChange,
  label,
  disabled = false,
  style = DropdownStyle.Muted,
  icon = DropdownIcon.DownArrow,
  iconPosition = DropdownIconPosition.Right,
  children,
}: Props) {
  const inputClasses = cn(s.input, {
    [s.primary]: style === DropdownStyle.Primary,
    [s.secondary]: style === DropdownStyle.Secondary,
    [s.text]: style === DropdownStyle.Text,
    [s.pill]: style === DropdownStyle.Pill,
    [s.deselected]: style === DropdownStyle.Deselected,
    [s.selected]: style === DropdownStyle.Selected,
    [s.muted]: style === DropdownStyle.Muted,
    [s.downArrow]: icon === DropdownIcon.DownArrow,
    [s.rightArrow]: icon === DropdownIcon.RightArrow,
    [s.calendar]: icon === DropdownIcon.Calendar,
    [s.plus]: icon === DropdownIcon.Plus,
    [s.check]: icon === DropdownIcon.Check,
    [s.iconRight]: iconPosition === DropdownIconPosition.Right,
    [s.iconLeft]: iconPosition === DropdownIconPosition.Left,
    [s.disabled]: disabled,
  });

  return (
    <div className={s.root}>
      <Popover
        open={open}
        onOpenChange={onOpenChange}
        renderTarget={(props) => (
          <span {...props} role="button" tabIndex={0} className={inputClasses}>
            <span className={s.label}>{label}</span>
          </span>
        )}
        renderContent={(props) => (
          <div {...props} className={cn(props.className, s.popover)}>
            {children}
          </div>
        )}
      />
    </div>
  );
}

interface PopoverProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  renderTarget: (props: any) => ReactNode;
  renderContent: (props: any) => JSX.Element;
}

function Popover({
  renderTarget,
  renderContent,
  open,
  onOpenChange,
}: PopoverProps) {
  const [maxHeight, setMaxHeight] = useState(null);

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: (o, event, reason) => !isNil(reason) && onOpenChange(o),
    strategy: "fixed",
    placement: "bottom-start",
    middleware: [
      offset(10),
      flip(),
      shift(),
      size({
        apply({ availableHeight }) {
          flushSync(() => setMaxHeight(availableHeight));
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();

  return (
    <>
      {renderTarget({
        ref: refs.setReference,
        ...getReferenceProps({
          onClick(event) {
            event.stopPropagation();
          },
        }),
      })}
      {open && (
        <FloatingFocusManager context={context} modal={false}>
          {renderContent({
            className: s.popover,
            ref: refs.setFloating,
            style: { ...floatingStyles, maxHeight },
            "aria-labelledby": headingId,
            ...getFloatingProps(),
          })}
        </FloatingFocusManager>
      )}
    </>
  );
}

export default DropdownInput;
