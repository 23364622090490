import { useCallback } from "react";
import { sortBy, startCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useL10n } from "@/lib/user";
import { graphql } from "@/lib/gql";
import { client } from "@/lib/graphql";
import Select from "@/molecules/Select";
import { DropdownIcon } from "@/components/molecules/DropdownInput";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/molecules/Tooltip";
import InfoIcon from "@/atoms/icons/info.svg";

import s from "./style.module.css";

interface SelectCustomAttributeProps {
  selectedAttribute: string;
  selectedSegment: string | null;
  onChange: (attribute: string, segment: string) => void;
}

function SelectEnumerableAttribute({
  selectedAttribute,
  onChange,
}: SelectCustomAttributeProps) {
  const { l10n } = useL10n();
  const router = useRouter();
  const { data: attributes = [] } = useEnumerableAttributes();
  const attributeOptions = sortBy(
    attributes.map(({ name }) => {
      let attrName: string;
      switch (name) {
        case "account_segment": {
          attrName = l10n["default_segment_attribute"] ?? name;
          break;
        }
        default: {
          attrName = name;
          break;
        }
      }
      attrName = startCase(attrName.replace("_", " "));
      return {
        id: name,
        label: attrName,
      };
    }),
    "label",
    // INFO: For now we are filtering lifecycle_stage out of this list.
    // This is because it results in confusing data on the cost-to-serve page.
    // This may be re-enabled in the future.
  ).filter((a) => a.id !== "lifecycle_stage");

  const handleChangeAttribute = useCallback(
    (attr: string) => onChange(attr, "all"),
    [onChange],
  );

  const handleClickSegmentInfo = useCallback(() => {
    router.push({
      pathname: "/settings",
      query: { ...router.query, "settings[tab]": "CRM" },
    });
  }, [router]);

  return (
    <div key={selectedAttribute} className={s.root}>
      <Tooltip>
        <TooltipTrigger className={s.tooltip} onClick={handleClickSegmentInfo}>
          <InfoIcon width={16} height={16} />
        </TooltipTrigger>
        <TooltipContent>{`Click to view the definition of your attributes on the CRM settings page.`}</TooltipContent>
      </Tooltip>
      <Select
        className={s.customAttributeOption}
        value={selectedAttribute}
        icon={DropdownIcon.RightArrow}
        onChange={handleChangeAttribute}
        options={attributeOptions}
        disabled={attributes.length <= 1}
      />
    </div>
  );
}

const QUERY_CUSTOM_ATTRIBUTES = graphql(`
  query CustomAttributes {
    company {
      customAttributes {
        name
        dataType
        unitOfMeasure
        values
      }
    }
  }
`);

export function useEnumerableAttributes() {
  const result = useCustomAttributes();
  return {
    ...result,
    data: result?.data
      ?.filter(({ dataType }) => dataType === "string")
      .map(({ name, values }) => ({
        name: name,
        segments: values,
      })),
  };
}

export function useCustomAttributes() {
  return useQuery({
    queryKey: ["custom-attributes"],
    queryFn: async () => {
      const { company } = await client.request(QUERY_CUSTOM_ATTRIBUTES);
      return company?.customAttributes ?? [];
    },
    useErrorBoundary: true,
  });
}

export default SelectEnumerableAttribute;
