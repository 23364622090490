import { useCallback, FormEvent, KeyboardEventHandler } from "react";
import cn from "classnames";

import s from "./style.module.css";

interface Props {
  type?: string;
  value?: string;
  error?: string;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (val: string) => void;
  onKeyPress?: KeyboardEventHandler;
  onBlur?: () => void;
  className?: string;
}

function Input({
  type = "text",
  value,
  onChange,
  error,
  className,
  ...rest
}: Props) {
  const handleOnChange = useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      onChange && onChange(evt.currentTarget.value);
    },
    [onChange],
  );

  return (
    <input
      className={cn(s.input, error && s.error, className)}
      value={value}
      onChange={handleOnChange}
      type={type}
      {...rest}
    />
  );
}

export default Input;
