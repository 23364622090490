import { useRef, useEffect, type ReactNode } from "react";
import { pick, reject } from "lodash";

import Button from "@/atoms/Button";
import { Entity, EntityKind, EntitySelector } from "@/lib/gql/graphql";
import EmployeeSearch from "@/organisms/EmployeeSearch";
import XIcon from "@/atoms/XIcon";
import PeopleIcon from "@/atoms/PeopleIcon";
import PersonIcon from "@/atoms/PersonIcon";
import CustomTeamIcon from "@/atoms/icons/teams-star.svg";

import { usePendingSubjects } from "@/organisms/TeamFilter";

import s from "./style.module.css";

interface EntitySelectProps {
  value: EntitySelector[];
  onChange: (selectors: EntitySelector[]) => void;
  filterByRole?: boolean;
  excludeTeamsFromSearch?: boolean;
  prompt?: ReactNode;
  focusOnMount?: boolean;
}

function EntitySelect({
  value,
  onChange,
  filterByRole = false,
  excludeTeamsFromSearch = false,
  focusOnMount = true,
  prompt,
}: EntitySelectProps) {
  const searchRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // Focus search box on mount
    if (focusOnMount) {
      searchRef.current.focus();
    }
  }, [focusOnMount]);

  const { data } = usePendingSubjects(
    value.map((v) => pick(v, ["id", "kind"])),
  );
  const pendingEntities = data || [];

  const handleToggleSelected = (entity: Entity) => {
    const entryIsEqual = (e: Entity) =>
      e.kind === entity.kind && e.id === entity.id;
    const alreadySelected = value.some(entryIsEqual);
    if (alreadySelected) {
      onChange(reject(value, entryIsEqual));
    } else {
      onChange([...value, { id: entity.id, kind: entity.kind }]);
    }
  };

  return (
    <div className={s.entitySelect}>
      <EmployeeSearch
        isMultiSelect
        onSelect={handleToggleSelected}
        selected={value}
        ref={searchRef}
        filterByRole={filterByRole}
        excludeTeamsFromSearch={excludeTeamsFromSearch}
      />
      <div>
        {value.length > 0 ? (
          <SelectedEntities
            entities={pendingEntities}
            onRemove={handleToggleSelected}
          />
        ) : (
          prompt
        )}
      </div>
    </div>
  );
}

interface SelectedEntitiesProps {
  entities: Entity[];
  onRemove: (entity: Entity) => void;
}

function SelectedEntities({ entities, onRemove }: SelectedEntitiesProps) {
  return (
    <ul className={s.selectedEmployees}>
      {entities.map((e) => (
        <li key={`${e.kind}-${e.id}`}>
          <Button style="text" onClick={() => onRemove(e)}>
            <XIcon />
          </Button>
          <EntityIcon kind={e.kind} />
          <span>{e.fullName}</span>
        </li>
      ))}
    </ul>
  );
}

function EntityIcon({ kind }: { kind: EntityKind }) {
  switch (kind) {
    case EntityKind.Employee:
      return <PersonIcon />;
    case EntityKind.OrgUnit:
      return <PeopleIcon />;
    case EntityKind.Team:
      return (
        <CustomTeamIcon className={s.customTeamIcon} width={16} height={16} />
      );
    default:
      return null;
  }
}
export default EntitySelect;
