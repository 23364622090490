import classnames from "classnames";

import EmployeeIcon from "@/atoms/EmployeeIcon";
import TeamIcon from "@/atoms/TeamIcon";
import XIcon from "@/atoms/XIcon";
import { EntityKind } from "@/lib/gql/graphql";
import { useTeamFilter, useSubjects } from "@/organisms/TeamFilter";

import styles from "./style.module.css";
import CustomTeamIcon from "@/atoms/icons/teams-star.svg";

function TeamFilterList() {
  const [teamFilters, setTeamFilter] = useTeamFilter();
  const { data } = useSubjects();

  const handleRemoveTeamFilter = (
    idToRemove: string,
    kindToRemove: EntityKind,
  ) => {
    setTeamFilter(
      teamFilters.filter(
        ({ id, kind }) => id !== idToRemove || kind !== kindToRemove,
      ),
    );
  };

  function renderEntityIcon(entityKind) {
    switch (entityKind) {
      case EntityKind.Employee:
        return <EmployeeIcon width={24} height={24} />;
      case EntityKind.OrgUnit:
        return <TeamIcon width={24} height={24} />;
      case EntityKind.Team:
        return (
          <CustomTeamIcon
            className={styles.customTeamIcon}
            width={16}
            height={16}
          />
        );
      default:
        return null;
    }
  }

  if (data?.length > 0) {
    return (
      <div className={styles.teamFilterList}>
        <span className={styles.label}>Viewing</span>
        {data.map(({ id, kind, fullName }) => (
          <span
            key={`${kind}-${id}`}
            className={styles.teamFilterItem}
            onClick={() => handleRemoveTeamFilter(id, kind)}
          >
            {renderEntityIcon(kind)}
            {fullName}
            <XIcon />
          </span>
        ))}
      </div>
    );
  } else if (data?.length === 0) {
    return (
      <div className={styles.teamFilterList}>
        <span className={styles.label}>Viewing</span>
        <span className={classnames(styles.teamFilterItem, styles.static)}>
          Full Team
        </span>
      </div>
    );
  } else {
    return (
      <div className={styles.teamFilterList}>
        <span className={styles.label}>Viewing</span>
      </div>
    );
  }
}

export default TeamFilterList;
