import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil } from "lodash";

import { graphql } from "@/lib/gql";
import { client } from "@/lib/graphql";
import { useToast } from "@/lib/contexts/toast";
import SimpleTooltip from "@/molecules/SimpleTooltip";
import Files from "@/atoms/icons/files.svg";
import ThumbsUp from "@/atoms/icons/hand-thumbs-up.svg";
import ThumbsDown from "@/atoms/icons/hand-thumbs-down.svg";
import Button from "@/atoms/Button";
import Input from "@/atoms/Input";
import { FeedbackType } from "@/lib/gql/graphql";

import s from "./style.module.css";

function useSubmitSummaryFeedback() {
  const queryClient = useQueryClient();
  return useMutation({
    /* @ts-ignore */
    mutationFn: async ({ summaryId, themedSummaryId, feedbackType, feedback }) => {
      const { submitSummaryFeedback: res } = await client.request(
        graphql(`
          mutation SubmitSummaryFeedback(
            $summaryId: ID!
            $themedSummaryId: ID
            $feedbackType: FeedbackType!
            $feedback: String
          ) {
            submitSummaryFeedback(
              summaryId: $summaryId
              themedSummaryId: $themedSummaryId
              feedbackType: $feedbackType
              feedback: $feedback
            ) {
              summary {
                id
                status
                createdAt
                title
                summary
                customerId
                customerName
                teamFilters {
                  id
                  kind
                  fullName
                }
                tools
                ticketSentiment
                from
                to
                feedbackType
              }
              errors
            }
          }
        `),
        {
          summaryId,
          themedSummaryId,
          feedbackType,
          feedback,
        },
      );
      return res;
    },
    onSuccess: (data) => {
      const summary = data.summary;
      queryClient.setQueryData(["summary", summary?.id], summary);
    },
    useErrorBoundary: true,
  });
}

interface SummaryFeedbackProps {
  summaryId: string;
  themedSummaryId?: string;
  summaryBody: string;
  feedbackType: FeedbackType;
}

function SummaryFeedback({
  summaryId,
  themedSummaryId,
  summaryBody,
  feedbackType,
}: SummaryFeedbackProps) {
  const { setToast } = useToast();
  const submitSummaryFeedback = useSubmitSummaryFeedback();
  const [moreFeedback, setMoreFeedback] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(summaryBody);
      setToast("success", "Summary copied to clipboard!");
    } catch (err) {
      setToast("error", "Failed to copy to clipboard!");
    }
  };

  const handleHelpful = () => {
    submitSummaryFeedback.mutate(
      /* @ts-ignore: Can't figure out this type error for the life of me */
      {
        summaryId,
        themedSummaryId,
        feedbackType: FeedbackType.Positive,
        feedback: null,
      },
      {
        onSuccess: () => {
          setToast("success", "Thank you for the feedback.");
          setMoreFeedback(false);
        },
      },
    );
  };

  const handleUnhelpful = () => {
    submitSummaryFeedback.mutate(
      /* @ts-ignore: Can't figure out this type error for the life of me */
      {
        summaryId,
        themedSummaryId,
        feedbackType: FeedbackType.Negative,
        feedback: null,
      },
    );
    setMoreFeedback(true);
  };

  const handleMoreFeedback = (feedback: string) => {
    submitSummaryFeedback.mutate(
      /* @ts-ignore: Can't figure out this type error for the life of me */
      {
        summaryId,
        themedSummaryId,
        feedbackType: FeedbackType.Negative,
        feedback,
      },
      {
        onSuccess: () => {
          setToast("success", "Thank you for the feedback.");
          setMoreFeedback(false);
        },
      },
    );
  };

  return (
    <div className={s.feedback}>
      <div className={s.feedbackBar}>
        <SimpleTooltip content="Copy">
          <Button size="smallest" style="text" onClick={handleCopy}>
            <Files width={20} height={20} />
          </Button>
        </SimpleTooltip>
        <SimpleTooltip content="Helpful">
          <Button size="smallest" style="text" onClick={handleHelpful}>
            <ThumbsUp
              width={20}
              height={20}
              className={
                feedbackType === FeedbackType.Positive ? s.selected : null
              }
            />
          </Button>
        </SimpleTooltip>
        <SimpleTooltip content="Not Helpful">
          <Button size="smallest" style="text" onClick={handleUnhelpful}>
            <ThumbsDown
              width={20}
              height={20}
              className={
                feedbackType === FeedbackType.Negative ? s.selected : null
              }
            />
          </Button>
        </SimpleTooltip>
      </div>
      {moreFeedback && <NegativeFeedback onClick={handleMoreFeedback} />}
    </div>
  );
}

interface NegativeFeedbackProps {
  onClick: (feedback) => void;
}

function NegativeFeedback({ onClick }: NegativeFeedbackProps) {
  const [other, setOther] = useState<string>(null);

  const NEGATIVE_FEEDBACK = [
    "A summary was not generated",
    "Not factually correct",
    "Too vague",
    "Don't like the style",
  ];

  const handleClick = (feedback) => {
    setOther(null);
    onClick(feedback);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleClick(other);
    }
  };

  return (
    <div className={s.moreFeedback}>
      <div>
        <span>Tell us more:</span>
      </div>
      <ul className={s.feedbackOptions}>
        {NEGATIVE_FEEDBACK.map((f) => (
          <Button
            onClick={() => handleClick(f)}
            key={f}
            style="tertiary"
            size="small"
          >
            {f}
          </Button>
        ))}
        <Button
          onClick={() => setOther("")}
          key="other"
          style="tertiary"
          size="small"
        >
          Other…
        </Button>
      </ul>
      {!isNil(other) && (
        <div className={s.feedbackOther}>
          <Input
            type="text"
            value={other}
            onChange={setOther}
            placeholder="Other…"
            onKeyPress={handleEnter}
            autoFocus
          />
          <Button size="small" onClick={() => handleClick(other)}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
}

export default SummaryFeedback;
