import classnames from "classnames";

import InfoCircle from "./info-circle.svg";
import styles from "./style.module.css";

interface Props {
  className?: string;
}

function InfoIcon({ className, ...rest }: Props) {
  const classes = classnames(styles.info, className);
  return <InfoCircle className={classes} {...rest} />;
}

export default InfoIcon;
