import { useCallback } from "react";
import { compact, sortBy, startCase } from "lodash";
import { useRouter } from "next/router";
import cn from "classnames";
import { useL10n } from "@/lib/user";
import { useEnumerableAttributes } from "@/organisms/SelectEnumerableAttribute";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/molecules/Tooltip";
import InfoIcon from "@/atoms/icons/info.svg";
import TreeMenu from "@/organisms/TreeMenu";
import { client } from "@/lib/graphql";
import { graphql } from "@/lib/gql";
import { useQuery } from "@tanstack/react-query";

import s from "./style.module.css";
import { toolName } from "@/lib/utils";

interface CrmThemeMenuProps {
  selectedSegment: {
    attribute: string;
    segment: string;
  } | null;
  onChange: (segment: { attribute: string; segment: string; label?: string }) => void;
  className?: string;
  includeGeneralSegment?: boolean;
  includeProactiveReactiveSegment?: boolean;
  includeInternalSegment?: boolean;
  includeAccountValueSegment?: boolean;
  includeToolSegment?: boolean;
  customerAttributesAllowList?: string[];
}

export function useConnectedTools() {
  return useQuery({
    queryKey: ["connected-tools"],
    queryFn: async () => {
      const { company } = await client.request(graphql(`
        query ConnectedTools {
          company {
            connectedTools
          }
        }
      `));

      return company?.connectedTools ?? [];
    },
    useErrorBoundary: true,
  });
}

function attributeLabel(name: string, l10n: Record<string, string>) {
  return startCase(
    name === "account_segment" && l10n.default_segment_attribute
      ? l10n.default_segment_attribute
      : name.replace("_", " "),
  );
}

function CrmThemeMenu({
  selectedSegment,
  onChange,
  className,
  includeGeneralSegment,
  includeProactiveReactiveSegment,
  includeInternalSegment,
  includeAccountValueSegment,
  includeToolSegment,
  customerAttributesAllowList,
}: CrmThemeMenuProps) {
  const { l10n } = useL10n();
  const router = useRouter();
  const { data: customerAttributes = [] } = useEnumerableAttributes();
  const { data: connectedTools = [] } = useConnectedTools();

  const options = compact([
    includeGeneralSegment ? {
      id: "general",
      label: "General",
      children: [{
        id: "general",
        attributeName: "overview",
        label: "Overview",
        selectedLabel: "General: Overview",
        children: [],
      }],
    } : null,
    includeProactiveReactiveSegment ? {
      id: "proactive_reactive",
      label: "Reactive",
      children: [
        // {
        //   id: "proactive_reactive",
        //   attributeName: "proactive",
        //   label: "Proactive",
        //   selectedLabel: "Proactive/Reactive: Proactive",
        //   children: [],
        // },
        {
          id: "proactive_reactive",
          attributeName: "reactive",
          label: "Reactive",
          selectedLabel: "Reactive",
          children: [],
        }
      ],
    } : null,
    includeInternalSegment ? {
      id: "internal_external",
      label: "Internal",
      children: [{
        id: "internal_external",
        attributeName: "non_client_internal",
        label: "Internal Non-Client Time",
        selectedLabel: "Internal Non-Client Time",
        children: [],
      }],
    } : null,
    ...sortBy(
      customerAttributes.filter((a) => customerAttributesAllowList == null ||customerAttributesAllowList.includes(a.name)).map((a) => ({
        id: a.name,
        label: attributeLabel(a.name, l10n),
        children: a.segments.sort().map((seg) => ({
          id: a.name,
          attributeName: seg,
          label: seg,
          selectedLabel: `${attributeLabel(a.name, l10n)}: ${seg}`,
          children: [],
        })),
      })),
      "label"
    ),
    includeAccountValueSegment ? {
      id: "account_arr",
      label: "Account Value",
      children: [{
        id: "account_arr",
        attributeName: "bottom_quartile_accounts",
        label: "Low Value Owned Accounts",
        selectedLabel: "Account Value: Low Value Owned Accounts",
        children: [],
      }],
    } : null,
    includeToolSegment ? {
      id: "tool",
      label: "Tool",
      children: sortBy(
        connectedTools.map((tool) => ({
          id: 'tool',
          attributeName: tool,
          label: toolName(tool, l10n),
          selectedLabel: `Tool: ${toolName(tool, l10n)}`,
          children: [],
        })),
        "selectedLabel"
      ),
    } : null,
  ]);

  const handleClickSegmentInfo = useCallback(() => {
    router.push({
      pathname: "/settings",
      query: { ...router.query, "settings[tab]": "CRM" },
    });
  }, [router]);

  const handleChange = (attribute, seg) => {
    onChange({
      attribute,
      segment: seg.attributeName,
      label: seg.selectedLabel,
    });
  };

  if (!selectedSegment) return null;

  return (
    <div className={cn(s.root, className)}>
      <TreeMenu
        options={options}
        value={`${selectedSegment.attribute};${selectedSegment.segment}`}
        labelClass={s.label}
        onChange={handleChange}
        withSearch
      />
      <Tooltip>
        <TooltipTrigger
          className={s.tooltip}
          onClick={handleClickSegmentInfo}
        >
          <InfoIcon width={16} height={16} />
        </TooltipTrigger>
        <TooltipContent>{`Click to view the definition of your attributes on the CRM settings page.`}</TooltipContent>
      </Tooltip>
    </div>
  );
}

export default CrmThemeMenu;